<script setup lang="ts">
import SmallCountdownBanner from '~/components/atoms/SmallCountdownBanner.vue'

const localePath = useLocalePath()
const drawers = useDrawers()
const priceFormatter = usePriceFormatter()
const { t, locales, locale } = useI18n()
const router = useRouter()

const { activeOrder } = useActiveOrder()

async function handleClose() {
  drawers.value.cart.open = false
  await nextTick()
}

async function redirectToCheckout() {
  await handleClose()
  await router.push(localePath('checkout'))
}

const localeCurrency = computed(() => {
  return locales.value.find(l => l.code === locale.value)?.currency
})

const prizeMoney = computed(() => {
  return priceFormatter(+useRuntimeConfig().public.prizeMoney[localeCurrency.value] * 100, localeCurrency.value)
})

const showCart = computed(() => drawers.value.cart.open)

// Computed property to calculate total savings
const totalSavings = computed(() => {
  if (!activeOrder.value?.lines) {
    return 0
  }
  return activeOrder.value.lines.reduce((total, line) => {
    const priceBeforeDiscount = line.productVariant.priceBeforeDiscount
    const price = line.unitPrice
    const quantity = line.quantity
    const savingsPerItem = (priceBeforeDiscount - price) * quantity
    return total + savingsPerItem
  }, 0)
})
</script>

<template>
  <NDrawer id="cart-drawer" slide-from="right" :show="showCart" :title="t('cart.label')" closable @close="handleClose">
    <ContentGuard>
      <div class="hidden bg-amber-200:35 px4 py2 sm:flex sm:items-center sm:px6">
        <NIcon icon="i-ri:timer-flash-line" n="slate8 xl" class="mr3 text-context -ml1" />
        <span class="text-ce text-sm c-slate-800 fw600">
          {{ t('cart.items_not_reserved') }}
        </span>
      </div>
    </ContentGuard>
    <div class="mt-8">
      <template v-if="activeOrder?.totalQuantity">
        <div class="flow-root">
          <ul role="list" class="-my-6 divide-y divide-slate-200">
            <CartLineItem
              v-for="line in activeOrder?.lines"
              :key="line.id"
              :line="line"
              :currency="activeOrder?.currency"
              class="px4 py6 sm:px6"
              @click:link="handleClose"
            />
          </ul>
        </div>
      </template>

      <div v-else class="h-48 flex items-center justify-center text-xl text-slate-400">
        {{ t('cart.empty.heading') }}
      </div>
    </div>

    <template v-if="activeOrder?.totalQuantity" #footer>
      <SmallCountdownBanner class="mt-4">
        <template #text>
          {{ t('trustable.productpage.countdown_banner.seconds') }}
        </template>
      </SmallCountdownBanner>
      <div class="pb-safe">
        <div class="">
          <div class="mx-auto max-w-xl p-2">
            <div class="grid grid-cols-2 gap-2">
              <div class="flex items-center border border-gray-200 rounded-lg bg-green-100 p-2 shadow-sm">
                <NIcon icon="i-hugeicons:delivery-truck-01" class="mr-2 flex-shrink-0 text-lg text-green-700" />
                <div>
                  <h2 class="text-sm text-green-700 font-bold leading-tight">
                    {{ t('incentives.free_shipping.title').toUpperCase() }}
                  </h2>
                </div>
              </div>
              <div class="flex items-center border border-gray-200 rounded-lg bg-gray-50 p-2 shadow-sm">
                <NIcon icon="i-heroicons:check-badge" class="mr-2 flex-shrink-0 text-lg text-gray-600" />
                <p class="text-xs text-gray-700">
                  {{ t('shipping.within_24_hours') }}
                </p>
              </div>
              <div class="flex items-center border border-gray-200 rounded-lg bg-gray-50 p-2 shadow-sm">
                <NIcon icon="i-heroicons:gift-top" class="mr-2 flex-shrink-0 text-lg text-gray-600" />
                <p class="text-xs text-gray-700 leading-tight">
                  {{ t('promotion.purchase_competition_prizes', [prizeMoney]) }}
                </p>
              </div>
              <div class="flex items-center border border-gray-200 rounded-lg bg-gray-50 p-2 shadow-sm">
                <NIcon icon="i-hugeicons:delivery-return-02" class="mr-2 flex-shrink-0 text-lg text-gray-600" />
                <p class="text-xs text-gray-700">
                  {{ t('trustable.productpage.return_xy_days', [$config.public.returnWindowDays]) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="pb6">
          <div v-if="totalSavings > 0" class="border-t n-border-base">
            <div class="flex justify-between bg-red-700 px4 py2 text-white fw600 sm:px6">
              <p v-text="t('order.summary.you_save.label')" />
              <p v-if="activeOrder.subTotal">
                {{ priceFormatter(totalSavings, activeOrder?.currency) }}
              </p>
            </div>
          </div>
          <div class="border-t n-border-base">
            <div class="flex justify-between px4 pt4 text-base text-slate-900 fw600 sm:px6">
              <p v-text="t('order.summary.subtotal.label')" />
              <p v-if="activeOrder.subTotal">
                {{ priceFormatter(activeOrder.subTotal, activeOrder.currency) }}
              </p>
            </div>
          </div>
          <div class="mt-6 px4 sm:px6">
            <NButton
              id="checkout"
              n="red-700 solid"
              class="w-full justify-center rounded-md color-white fw600 leading-10 opacity-100"
              @click="redirectToCheckout"
            >
              {{ t('checkout.label') }}
            </NButton>
          </div>
        </div>
      </div>
    </template>
  </NDrawer>
</template>
