<script setup lang="ts">
import { onBeforeUnmount, onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface Countdown {
  days: string
  hours: string
  minutes: string
  seconds: string
}

const countdown = reactive<Countdown>({
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00',
})

function updateCountdown() {
  const now = new Date()
  const target = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
  let diff = target.getTime() - now.getTime()

  if (diff <= 0) {
    // Countdown is over
    countdown.days = '00'
    countdown.hours = '00'
    countdown.minutes = '00'
    countdown.seconds = '00'
    // Optionally, clear the interval to stop updating
    // clearInterval(intervalId)
    return
  }

  const days = Math.floor(diff / (1000 * 60 * 60 * 24))
  diff %= 1000 * 60 * 60 * 24
  const hours = Math.floor(diff / (1000 * 60 * 60))
  diff %= 1000 * 60 * 60
  const minutes = Math.floor(diff / (1000 * 60))
  diff %= 1000 * 60
  const seconds = Math.floor(diff / 1000)

  countdown.days = days.toString().padStart(2, '0')
  countdown.hours = hours.toString().padStart(2, '0')
  countdown.minutes = minutes.toString().padStart(2, '0')
  countdown.seconds = seconds.toString().padStart(2, '0')
}

let intervalId: ReturnType<typeof setInterval>

onMounted(() => {
  updateCountdown()
  intervalId = setInterval(updateCountdown, 1000)
})

onBeforeUnmount(() => {
  clearInterval(intervalId)
})
</script>

<template>
  <div class="mx-auto w-full">
    <div class="mx2 flex items-center justify-between rounded-lg bg-red-100/80 p2 text-red-950">
      <div class="text-xs text-red-950 font-bold">
        Rabaty kończą się wkrótce&nbsp;⏰
      </div>
      <div class="flex items-center space-x-1">
        <div class="flex flex-col items-center">
          <div class="h-10 w-10 flex items-center justify-center rounded bg-white px-3 py-2 text-xl text-red-950 font-bold">
            <span>{{ countdown.days }}</span>
          </div>
          <div class="mt-1.5 text-xs text-red-950 font-bold">
            {{ t('trustable.productpage.countdown_banner.days') }}
          </div>
        </div>
        <div class="mt-1.5 self-start text-xl text-red-950 font-bold">
          :
        </div>
        <div class="flex flex-col items-center">
          <div class="h-10 w-10 flex items-center justify-center rounded bg-white px-3 py-2 text-xl text-red-950 font-bold">
            <span>{{ countdown.hours }}</span>
          </div>
          <div class="mt-1.5 text-xs text-red-950 font-bold">
            {{ t('trustable.productpage.countdown_banner.hours') }}
          </div>
        </div>
        <div class="mt-1.5 self-start text-xl text-red-950 font-bold">
          :
        </div>
        <div class="flex flex-col items-center">
          <div class="h-10 w-10 flex items-center justify-center rounded bg-white px-3 py-2 text-xl text-red-950 font-bold">
            <span>{{ countdown.minutes }}</span>
          </div>
          <div class="mt-1.5 text-xs text-red-950 font-bold">
            {{ t('trustable.productpage.countdown_banner.minutes') }}
          </div>
        </div>
        <div class="mt-1.5 self-start text-xl text-red-950 font-bold">
          :
        </div>
        <div class="flex flex-col items-center">
          <div class="h-10 w-10 flex items-center justify-center rounded bg-white px-3 py-2 text-xl text-red-950 font-bold">
            <span>{{ countdown.seconds }}</span>
          </div>
          <div class="mt-1.5 text-xs text-red-950 font-bold">
            <slot name="text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
